import React, { useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Popover from "@material-ui/core/Popover";
const axios = require("axios");

import Navigation from "../components/Navigation";

import Layout from "../components/layout";
import ColorButton from "../components/ColorButton";
import "../styles/main.scss";

import { Helmet } from 'react-helmet';

export default function Kontakt() {
    const [ sentPopoverOpen, setSentPopoverOpen ] = React.useState(false);
    const [ confirmPopoverOpen, setConfirmPopoverOpen ] = React.useState(false);
    const [ formInformation, setFormInformation ] = React.useState({});
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ telError, setTelError ] = useState(false);
    const [ datenschutzError, setDatenschutzError ] = useState(false);
    const [ kontaktError, setKontaktError ] = useState(false);
    const [ formRef, setFormRef ] = useState("");
    const [ popoverTel, setPopoverTel ] = useState("");
    const [ kunde, setKunde ] = useState("");
    const divRef = React.useRef();
    const vornameRef = useRef();
    const nachnameRef = useRef();
    const telefonRef = useRef();
    const nachrichtRef = useRef();
    const datenschutzRef = useRef();
    const kontaktRef = useRef();
    const vonRef = useRef();
    const bisRef = useRef();
    const honeypotNameRef = useRef();
    const honeypotMailRef = useRef();
    const honeypotTelRef = useRef();
    const [ formResponse, setFormResponse ] = useState("");

    const [ dayState, setDayState ] = useState({
        montag: false,
        dienstag: false,
        mittwoch: false,
        donnerstag: false,
        freitag: false,
    });
    const { montag, dienstag, mittwoch, donnerstag, freitag } = dayState;
    let formData = {};

    const handleDayChange = (e) => {
        setDayState({
            ...dayState,
            [e.target.name]: e.target.checked,
        });
    };

    const handleConfirmPopoverClose = () => {
        setConfirmPopoverOpen(false);
        setAnchorEl(null);
    };
    const handleSentPopoverClose = () => {
        setSentPopoverOpen(false);
        setAnchorEl(null);
    };

    const handleKundeChange = (e) => {
        setKunde(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let _access = 1;
        if (honeypotNameRef.current.value || honeypotMailRef.current.value || honeypotTelRef.current.value) {
            _access = 0;
        }
        const vorname = vornameRef.current.value;
        const nachname = nachnameRef.current.value;
        const nachricht = nachrichtRef.current.value;
        setPopoverTel(telefonRef.current.value);
        const telefon = telefonRef.current.value;
        let error = false;
        if (telefon && !telefon.match(/^\+{0,1}[0-9]{2,3}[\s/-]{1}[0-9]{2,4}[\s/-]{0,1}[0-9]{3,8}[\s/-]{0,1}[0-9]{0,6}$/)) {
            error = true;
            setTelError(true);
        } else {
            setTelError(false);
        }
        if (!datenschutzRef.current.checked) {
            error = true;
            setDatenschutzError(true);
            return;
        } else {
            setDatenschutzError(false);
        }
        if (!kontaktRef.current.checked) {
            error = true;
            setKontaktError(true);
            return;
        } else {
            setKontaktError(false);
        }


        formData = { nachricht, vorname, nachname, telefon, kunde, _access };
        if (error) {
            return;
        }
        if (telefon) {
            await sendForm(formData);
            error = false;
        }
    };

    async function postForm() {
        try {
            const { to, subject, body, _access } = formInformation;
            const response = await axios.post(`${window.config.NODEMAILER_URL}/send`,
                { to, subject, body, _access },
                { headers: {
                    "content-type": "application/json"
                }
                });
            if (response.status >= 200 < 300) {
                let greeting = "Vielen Dank!";
                setFormResponse(
                    <>
                        <div className="formSuccess">
                            <h2 className="formSuccess">
                                {greeting}
                            </h2>
                            Wir haben Ihre Daten erfolgreich entgegengenommen und werden Sie in den nächsten Tagen unter der von Ihnen angegebenen Telefonnummer kontaktieren.
                        </div>
                        <br />
                        <ColorButton className="formSubmit" aria-describedby={sentPopoverId} onClick={handleSentPopoverClose}>
                            Schließen
                        </ColorButton>
                    </>
                );
                formRef.reset();
            }
        } catch (err) {
            if (err.response) {
                const mailto = `mailto:hilfe@fehlzeitenerfassung.de?subject=Unbekannter%20Fehler%20${err.response.status}%20bei%20einer%20Rückrufbitte`;
                setFormResponse(
                    <>
                        <div className="formFail">
                            <h2 className="formFail">
                                Wir konnten Ihre Daten leider nicht entgegennehmen.
                            </h2>
                            Falls dieser Fehler später erneut auftritt, kontaktieren Sie bitte den <b><a href={mailto}>Support</a></b>!
                        </div>
                        <br />
                        <ColorButton className="schliessen" aria-describedby={sentPopoverId} onClick={handleSentPopoverClose}>
                            Schließen
                        </ColorButton>
                    </>
                );
            } else {
                setFormResponse(
                    <>
                        <div className="formFail">
                            <h2 className="formFail">
                                Unerwarteter Serverfehler
                            </h2>
                            Bitte versuchen Sie es später erneut. Falls dieser Fehler später erneut auftritt, kontaktieren Sie bitte den <b><a href="mailto:hilfe@fehlzeitenerfassung.de?subject=Unbekannter%20Serverfehler%20bei%20einer%20Rückrufbitte">Support</a></b>.
                        </div>
                        <br />
                        <ColorButton className="schliessen" aria-describedby={sentPopoverId} variant="contained" onClick={handleSentPopoverClose}>
                            Schließen
                        </ColorButton>
                    </>
                );
            }
        } finally {
            setAnchorEl(divRef.current);
            setSentPopoverOpen(true);
            handleConfirmPopoverClose();
        }
    }

    async function sendForm(formData) {
        let subject = "Rückrufbitte";
        const { nachricht, vorname, nachname, telefon, kunde, _access } = formData;
        let tage = "";
        montag ? tage += "Montag, " : "";
        dienstag ? tage += "Dienstag, " : "";
        mittwoch ? tage += "Mittwoch, " : "";
        donnerstag ? tage += "Donnerstag, " : "";
        freitag ? tage += "Freitag, " : "";

        if (tage) {
            tage = tage.slice(0, tage.length - 2);
        }

        let zeit = "";
        if (vonRef.current.value) {
            zeit = `von ${vonRef.current.value}`;
        }
        if (bisRef.current.value) {
            zeit += ` bis ${bisRef.current.value}`;
        }
        if (_access != 1) {
            const mailto = "mailto:hilfe@fehlzeitenerfassung.de?subject=%20Fehler%20bei%20einer%20Rückrufbitte%20(hidden%20input%20filled)";
            setFormResponse(
                <div className="formFail">
                    <h2 className="formFail">
                        Wir konnten Ihre Daten leider nicht entgegennehmen.
                    </h2>
                    Bitte kontaktieren Sie den <b><a href={mailto}>Support</a></b>.
                </div>
            );
            setSentPopoverOpen(true);
            return;
        }
        if (vorname && nachname) {
            subject += ` von ${vorname} ${nachname}`;
        } else {
            subject += ` von ${telefon}`;
        }
        const to = "kontakt@fehlzeitenerfassung.de";
        const body = 
            "Neue Anfrage von:\n\n" +
            `Vorname: ${vorname || "unbekannt"}\n` +
            `Nachname: ${nachname || "unbekannt"}\n\n` +
            `Telefon: ${telefon}\n` +
            `Bereits Kunde: ${kunde || "unbekannt"}\n` +
            `Bevorzugte Wochentage: ${tage || "unbekannt"}\n` +
            `Bevorzugte Anrufzeit: ${zeit + " Uhr" || "unbekannt"}\n\n` +
            `Nachricht: \n${nachricht}`;

        console.log(body);
        setFormInformation({ to, subject, body, _access });
        setConfirmPopoverOpen(true);
    }

    const sentPopoverId = sentPopoverOpen ? "form-popover" : undefined;
    const confirmPopoverId = confirmPopoverOpen ? "form-popover" : undefined;

    const sentPopover = (
        <div>
            <Popover
                id={sentPopoverId}
                open={sentPopoverOpen}
                anchorEl={anchorEl}
            >
                {formResponse}
            </Popover>
        </div>
    );

    const confirmationPopover = (
        <div>
            <Popover
                id={confirmPopoverId}
                open={confirmPopoverOpen}
                anchorEl={anchorEl}
            >
                <p className="confirmationPopover">
                    Bitte prüfen Sie vor dem Absenden, ob die von Ihnen eingetragene Telefonnummer <b className="mailConfirmation">{popoverTel}</b> korrekt ist.
                    <br />
                    Wenn Sie Ihre Telefonnummer geprüft haben, können Sie entweder <b>"Schließen"</b> klicken,
                    um sie zu korrigieren, oder Sie klicken auf <b>Absenden</b>, um das Formular endgültig abzusenden.
                </p>
                <div className="confirmationButtons">
                    <ColorButton className="schliessen" aria-describedby={confirmPopoverId} onClick={handleConfirmPopoverClose}>
                        Schließen
                    </ColorButton>
                    <ColorButton className="formSubmit" aria-describedby={confirmPopoverId} onClick={postForm}>
                        Absenden
                    </ColorButton>
                </div>
            </Popover>
        </div>
    );

    const form = (
        <div className="formContainer rueckruf" ref={divRef}>
            <h2>Rückruf-Anfrage:</h2>
            <form ref={(el) => setFormRef(el)} className="testenForm" onSubmit={handleSubmit}>
                <TextareaAutosize
                    className="message"
                    ref={nachrichtRef}
                    aria-label="nachricht"
                    rows="5"
                    placeholder="Mitteilung/ Ihr Anliegen *"
                    required
                />
                <TextField inputProps={{ "ref": vornameRef }} label="Vorname:"  />
                <TextField inputProps={{ "ref": nachnameRef }} label="Nachname:"  />
                { telError ? <h4 className="formError">Bitte geben Sie die Telefonnummer mit Länderkennung wie im unten stehenden Beispiel an.</h4> : ""}
                <TextField inputProps={{ "ref": telefonRef }} label="Telefonnummer:" helperText="z.B. +49 2336 9242360" required={true} />
                <FormControl component="fieldset" variant="standard">
                    <FormLabel>Bevorzugte Rückruftage:</FormLabel><br />
                    <FormGroup className="dayPicker">
                        <FormControlLabel control={<Checkbox checked={montag} onChange={handleDayChange} name="montag" />}
                            label="Montag"
                        />
                        <FormControlLabel control={<Checkbox checked={dienstag} onChange={handleDayChange} name="dienstag" />}
                            label="Dienstag"
                        />
                        <FormControlLabel control={<Checkbox checked={mittwoch} onChange={handleDayChange} name="mittwoch" />}
                            label="Mittwoch"
                        />
                        <FormControlLabel control={<Checkbox checked={donnerstag} onChange={handleDayChange} name="donnerstag" />}
                            label="Donnerstag"
                        />
                        <FormControlLabel control={<Checkbox checked={freitag} onChange={handleDayChange} name="freitag" />}
                            label="Freitag"
                        />
                    </FormGroup>
                </FormControl>
                <FormLabel className="timeLabel">Bevorzugte Rückrufzeit:</FormLabel>
                <div className="callTime">
                    <div className="fromTime">
                        <FormLabel>Von:</FormLabel><br/>
                        <TextField inputProps={{ "ref": vonRef }} type="time" />
                    </div>
                    <div className="toTime">
                        <FormLabel>Bis:</FormLabel><br/>
                        <TextField inputProps={{ "ref": bisRef }} type="time" />
                    </div>
                </div>
                <FormControl className="kundeRadioButtons">
                    <FormLabel id="demo-radio-buttons-group-label">Sind Sie bereits Kunde?</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        defaultValue=""
                        onChange={handleKundeChange}
                    >
                        <FormControlLabel value="Ja" control={<Radio />} label="Ja" />
                        <FormControlLabel value="Nein" control={<Radio />} label="Nein" />
                    </RadioGroup>
                </FormControl>
                {/* H o n e y p o t     fields */}
                <label className="ohnohoney" htmlFor="name"></label>
                <input className="ohnohoney" ref={honeypotNameRef} tabIndex="-1" autoComplete="off" type="text" id="name" name="name" placeholder="Your name here" />
                <label className="ohnohoney" htmlFor="email"></label>
                <input className="ohnohoney" ref={honeypotMailRef} tabIndex="-1" autoComplete="off" type="email" id="email" name="email" placeholder="Your e-mail here" />
                <label className="ohnohoney" htmlFor="tel"></label>
                <input className="ohnohoney" ref={honeypotTelRef} tabIndex="-1" autoComplete="off" type="tel" id="tel" name="tel" placeholder="Your phone number here" />
                <br/>
                { kontaktError ? <h4 className="formError kontakt">Sie können die Anfrage nur absenden, wenn Sie einem Rückruf zustimmen.</h4> : "" }
                <div className="kontaktCheck">
                    <div className="checkboxDiv">
                        <input ref={kontaktRef} className="kontaktCheckbox" type="checkbox"/>
                    </div>
                    <p>Ich stimme einer telefonischen Kontaktaufnahme durch die Fehlzeitenerfassung</p>
                </div>
                { datenschutzError ? <h4 className="formError datenschutz">Sie können die Anfrage nur absenden, wenn Sie die Datenschutzbedingungen zuvor zur Kenntnis nehmen.</h4> : "" }
                <div className="datenschutzCheck">
                    <div className="checkboxDiv">
                        <input ref={datenschutzRef} className="datenschutzCheckbox" type="checkbox"/>
                    </div>
                    <p>Hiermit bestätige ich, dass ich die <a href="/datenschutz" target="_blank">Datenschutzbestimmungen</a> zur Kenntnis genommen habe.</p>
                </div>
                <div>
                    <ColorButton className="formSubmit" type="submit">Absenden</ColorButton>
                </div>
            </form>
        </div>
    );

    return (
        <Layout>
            <Helmet>
                <script src="/config.js"></script>
            </Helmet>
            <Navigation path="/kontakt" />
            <h1 className="formHeading">Kontakt</h1>
            <p>
                Wenn Sie schriftlichen Kontakt zu uns aufnehmen wollen, senden Sie bitte eine E-Mail an <b><a href="mailto:info@fehlzeitenerfassung.de?Kontaktformular">info@fehlzeitenerfassung.de</a></b>
            </p>
            <p>
                Falls Sie um einen Rückruf bitten möchten, füllen Sie bitte das folgende Formular aus:
            </p>
            <br />

            {sentPopover}
            {confirmationPopover}
            {form}
        </Layout>
    );
}

